export default {

  userRoleOptions: [
    { label: 'Admin', value: 1 },
    { label: 'Personel', value: 2 },
    { label: 'Customer', value: 3 },
  ],

  userStatusOptions: [
    { label: 'Personel', value: 1 },
    { label: 'Subscriber', value: 2 },
    { label: 'Customer', value: 3 },
  ],

  sourceTypeOptions: [
    { label: 'Map/Region', value: 1 },
    { label: 'Modern Proximity/ Modern Mixed Mode', value: 2 },
    { label: 'Ancient Proximity', value: 3 },
    { label: 'Ancient Mixed Mode', value: 4 },
  ],

  productTypeOptions: [
    { label: 'No Coordinate', value: 1 },
    { label: 'Has Coordinate', value: 2 },
  ],

  ticketTypeOptions: [
    { label: 'DEEP ANCESTRY', value: 0 },
    { label: 'TRAITS & HEALTH', value: 1 },
  ],

  orderFileTypeOptions: [
    'text/plain',
    'application/vnd.ms-excel',
    'application/x-zip-compressed',
    'application/x-rar-compressed',
  ],

  orderFileTypeJustTxt: [
    'text/plain',
  ],

  ticketFileTypeOptions: [
    'image/apng',
    'image/png',
    'image/jpg',
    'image/jpeg',
  ],
}
